// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-assists-js": () => import("./../../../src/pages/assists.js" /* webpackChunkName: "component---src-pages-assists-js" */),
  "component---src-pages-atencion-reque-js": () => import("./../../../src/pages/Atencion_reque.js" /* webpackChunkName: "component---src-pages-atencion-reque-js" */),
  "component---src-pages-consulteria-js": () => import("./../../../src/pages/consulteria.js" /* webpackChunkName: "component---src-pages-consulteria-js" */),
  "component---src-pages-contabilidad-elec-js": () => import("./../../../src/pages/contabilidad_elec.js" /* webpackChunkName: "component---src-pages-contabilidad-elec-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-factura-electro-js": () => import("./../../../src/pages/Factura_electro.js" /* webpackChunkName: "component---src-pages-factura-electro-js" */),
  "component---src-pages-impuestos-js": () => import("./../../../src/pages/Impuestos.js" /* webpackChunkName: "component---src-pages-impuestos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-initial-training-js": () => import("./../../../src/pages/initial_training.js" /* webpackChunkName: "component---src-pages-initial-training-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nomina-js": () => import("./../../../src/pages/Nomina.js" /* webpackChunkName: "component---src-pages-nomina-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/Policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/Prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-servicio-consu-js": () => import("./../../../src/pages/servicio_consu.js" /* webpackChunkName: "component---src-pages-servicio-consu-js" */),
  "component---src-pages-taxes-and-tax-planning-js": () => import("./../../../src/pages/taxes_and_tax_planning.js" /* webpackChunkName: "component---src-pages-taxes-and-tax-planning-js" */)
}

